'use client'

import {
  cache,
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import clsx from 'clsx'
import { motion, useReducedMotion } from 'framer-motion'

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'

import { cn } from '@/lib/utils'

import { ThemeToggle } from './ThemeToggle'
import { FadeInSmall } from './animations/FadeIn'
import { createClient } from '@/db/client'
import { Settings } from 'lucide-react'

async function getIsUserPro() {
  const db = createClient()
  const {
    data: { user },
    error: userError,
  } = await db.auth.getUser()

  if (userError || !user) {
    return {
      success: false,
      message: 'Failed to get user',
      data: undefined,
    }
  }

  try {
    const { data, error } = await db
      .from('subscriptions')
      .select('status')
      .eq('user_id', user?.id)

    console.log('Header- data', data)
    console.log('Header- error', error)
    return (
      data?.some((subscription) => subscription.status === 'active') ?? false
    )
  } catch (error) {
    console.error('Error:', error)
    return false
  }
}

const getUserProStatus = cache(getIsUserPro)

const RootLayoutContext = createContext<{
  logoHovered: boolean
  setLogoHovered: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

function XIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="m5.636 4.223 14.142 14.142-1.414 1.414L4.222 5.637z" />
      <path d="M4.222 18.363 18.364 4.22l1.414 1.414L5.636 19.777z" />
    </svg>
  )
}

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M2 6h20v2H2zM2 16h20v2H2z" />
    </svg>
  )
}

function StarAIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...props}
        d="M6.5 13l.784 1.569c.266.53.399.796.576 1.026a3 3 0 00.545.545c.23.177.495.31 1.026.575L11 17.5l-1.569.785c-.53.265-.796.398-1.026.575a3 3 0 00-.545.545c-.177.23-.31.495-.576 1.026L6.5 22l-.784-1.569c-.266-.53-.399-.796-.576-1.026a3 3 0 00-.545-.545c-.23-.177-.495-.31-1.026-.575L2 17.5l1.569-.785c.53-.265.796-.398 1.026-.575a3 3 0 00.545-.545c.177-.23.31-.495.576-1.026L6.5 13zM15 2l1.179 3.064c.282.734.423 1.1.642 1.409a3 3 0 00.706.706c.309.22.675.36 1.409.642L22 9l-3.064 1.179c-.734.282-1.1.423-1.409.642a3 3 0 00-.706.706c-.22.309-.36.675-.642 1.409L15 16l-1.179-3.064c-.282-.734-.423-1.1-.642-1.409a3 3 0 00-.706-.706c-.309-.22-.675-.36-1.409-.642L8 9l3.064-1.179c.734-.282 1.1-.423 1.409-.642a3 3 0 00.706-.706c.22-.309.36-.675.642-1.409L15 2z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Header({
  panelId,
  icon: Icon,
  expanded,
  onToggle,
  toggleRef,
  invert = false,
}: {
  panelId: string
  icon: React.ComponentType<{ className?: string }>
  expanded: boolean
  onToggle: () => void
  toggleRef: React.RefObject<HTMLButtonElement>
  invert?: boolean
}) {
  const [isUserPro, setIsUserPro] = useState(false)
  let { logoHovered, setLogoHovered } = useContext(RootLayoutContext)!
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const hText = pathname?.includes('library')
    ? 'All Guides'
    : pathname?.includes('enrolled')
    ? 'My Guides'
    : pathname?.includes('qa')
    ? 'File QA'
    : pathname?.includes('text')
    ? 'Guide Builder'
    : pathname?.includes('write')
    ? 'Write'
    : pathname?.includes('file')
    ? 'File Guide'
    : pathname?.includes('create')
    ? 'Create'
    : pathname?.includes('credits')
    ? 'Credits'
    : pathname?.includes('edit')
    ? 'Edit'
    : pathname?.includes('read')
    ? 'Read'
    : pathname?.includes('courses')
    ? 'Read'
    : 'gnow'

  const hideNavButtonOnCreate = searchParams?.has('loading')

  useEffect(() => {
    const fetchProStatus = async () => {
      try {
        const result = await getUserProStatus()
        if (typeof result === 'boolean') {
          setIsUserPro(result)
        } else if (typeof result === 'object' && 'success' in result) {
          // Handle the case where an object is returned
          setIsUserPro(false) // or handle it based on your logic
          console.log(result.message) // Log the error message
        } else {
          // Handle null case
          setIsUserPro(false)
        }
      } catch (error) {
        console.error('Error fetching user pro status:', error)
        setIsUserPro(false)
      }
    }

    fetchProStatus()
  }, [])

  return (
    <Container>
      <div className="flex items-center justify-between">
        <Link
          href="/"
          aria-label="Home"
          onMouseEnter={() => setLogoHovered(true)}
          onMouseLeave={() => setLogoHovered(false)}
        >
          <FadeInSmall>
            <h2
              className={cn(
                invert
                  ? 'text-white dark:text-black'
                  : 'text-black dark:text-white',
                'font-brand text-4xl font-semibold md:text-5xl',
              )}
            >
              {hText}
            </h2>
          </FadeInSmall>
        </Link>
        <div className="flex items-center gap-x-8">
          <div className="flex items-center gap-x-2">
            {isUserPro ? (
              <FadeInSmall>
                <Button href="/account" invert={invert}>
                  <div className="flex gap-1">
                    <Settings className="fill-brand-500/50 stroke-brand-700/50 stroke-1 dark:fill-brand-500 dark:stroke-brand-200/50" />
                    <span>Account</span>
                  </div>
                </Button>
              </FadeInSmall>
            ) : null}

            <div className="hidden md:block">
              <Button href="/create" invert={invert}>
                <div className="flex gap-1">
                  <StarAIcon className="fill-brand-500 stroke-brand-700/50 stroke-1 dark:fill-brand-500 dark:stroke-brand-200/50" />
                  <span>Create</span>
                </div>
              </Button>
            </div>
          </div>

          <button
            ref={toggleRef}
            type="button"
            disabled={hideNavButtonOnCreate}
            onClick={onToggle}
            aria-expanded={expanded ? 'true' : 'false'}
            aria-controls={panelId}
            className={clsx(
              'group -m-2.5 rounded-full p-2.5 transition',
              invert ? 'hover:bg-white/10' : 'hover:bg-neutral-950/10',
              hideNavButtonOnCreate ? 'hidden' : '',
            )}
            aria-label="Toggle navigation"
          >
            <Icon
              className={clsx(
                'h-6 w-6',
                invert
                  ? 'fill-white group-hover:fill-neutral-200 dark:fill-black dark:group-hover:fill-neutral-700'
                  : 'fill-neutral-950 group-hover:fill-neutral-700 dark:fill-neutral-100 dark:group-hover:fill-neutral-200',
              )}
            />
          </button>
        </div>
      </div>
    </Container>
  )
}

function NavigationRow({ children }: { children: React.ReactNode }) {
  return (
    <div className="even:mt-px sm:bg-neutral-950 sm:dark:bg-neutral-50">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2">{children}</div>
      </Container>
    </div>
  )
}

function NavigationItem({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Link
      href={href}
      className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px dark:bg-neutral-50 sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-vanta-800 sm:even:pl-16 dark:sm:even:border-l sm:dark:even:border-vanta-100/60"
    >
      {children}
      <span className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100  dark:bg-neutral-100" />
    </Link>
  )
}

function Navigation({ isUserPro }) {
  return (
    <nav className="mt-px font-sans text-5xl font-medium tracking-tight text-white dark:text-black">
      <NavigationRow>
        <NavigationItem href="/enrolled/1">My Guides</NavigationItem>
        <NavigationItem href="/library/1">Community Guides</NavigationItem>
      </NavigationRow>
      <NavigationRow>
        <NavigationItem href="/create">Create New</NavigationItem>
        <NavigationItem href={isUserPro ? '/account' : '/credits'}>
          {isUserPro ? 'Membership' : 'Go Pro'}
        </NavigationItem>
      </NavigationRow>
    </nav>
  )
}

function RootLayoutInner({
  children,
  path,
  isUserPro,
}: {
  children: React.ReactNode
  path: string
  isUserPro: boolean
}) {
  let panelId = useId()
  let [expanded, setExpanded] = useState(false)
  let openRef = useRef<React.ElementRef<'button'>>(null)
  let closeRef = useRef<React.ElementRef<'button'>>(null)
  let navRef = useRef<React.ElementRef<'div'>>(null)
  let shouldReduceMotion = useReducedMotion()

  useEffect(() => {
    function onClick(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        event.target.closest('a')?.href === window.location.href
      ) {
        setExpanded(false)
      }
    }

    window.addEventListener('click', onClick)

    return () => {
      window.removeEventListener('click', onClick)
    }
  }, [])

  return (
    <>
      {/* <MotionConfig transition={shouldReduceMotion ? { duration: 0 } : undefined}> */}
      {/* <MotionConfig> */}
      <header>
        <div
          className="absolute left-0 right-0 top-2 z-40 pt-14"
          aria-hidden={expanded ? 'true' : undefined}
          // @ts-ignore (https://github.com/facebook/react/issues/17157)
          inert={expanded ? '' : undefined}
        >
          <Header
            panelId={panelId}
            icon={MenuIcon}
            toggleRef={openRef}
            expanded={expanded}
            onToggle={() => {
              setExpanded((expanded) => !expanded)
              window.setTimeout(
                () => closeRef.current?.focus({ preventScroll: true }),
              )
            }}
          />
        </div>

        <motion.div
          layout
          id={panelId}
          style={{ height: expanded ? 'auto' : '0.5px' }}
          className={cn(
            'bg-neutral-950 dark:bg-white',
            'relative z-50 overflow-hidden  pt-2 ',
          )}
          aria-hidden={expanded ? undefined : 'true'}
          // @ts-ignore (https://github.com/facebook/react/issues/17157)
          inert={expanded ? undefined : ''}
        >
          <motion.div layout className={cn('bg-neutral-800 dark:bg-white')}>
            <div
              ref={navRef}
              className={cn(
                'bg-neutral-950 pb-16 pt-14 dark:bg-white',
                ' pb-16 pt-14 ',
              )}
            >
              <Header
                invert
                panelId={panelId}
                icon={XIcon}
                toggleRef={closeRef}
                expanded={expanded}
                onToggle={() => {
                  setExpanded((expanded) => !expanded)
                  window.setTimeout(
                    () => openRef.current?.focus({ preventScroll: true }),
                  )
                }}
              />
            </div>
            <Navigation isUserPro={isUserPro} />
            <div className="relative bg-vanta-950 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-vanta-800 dark:bg-white dark:before:bg-white">
              <Container>
                <div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
                  {/* <h2 className="font-sans text-base font-semibold text-white dark:text-black">
                      AI Credits
                      </h2> */}

                  {!isUserPro ? (
                    <div className="flex flex-col items-start justify-start">
                      <h2 className="font-sans text-base font-semibold text-white dark:text-black">
                        Want more?
                      </h2>

                      <Button href="/credits">
                        <div className="flex gap-1">
                          <StarAIcon className="fill-brandBlue-400 stroke-brandBlue-700/50 stroke-1 dark:fill-brandBlue-400 dark:stroke-brandBlue-200/60" />
                          <span>Go pro</span>
                        </div>
                      </Button>
                    </div>
                  ) : null}
                  <div className="sm:border-l sm:border-transparent sm:pl-16">
                    <h2 className="font-sans text-base font-semibold text-white dark:text-black">
                      Theme
                    </h2>
                    <ThemeToggle />
                  </div>
                </div>
              </Container>
            </div>
          </motion.div>
        </motion.div>
      </header>

      <motion.div
        layout
        style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
        className={cn(
          'bg-neutral-50 dark:bg-neutral-950',
          'relative flex min-h-screen  flex-auto pt-14',
        )}
      >
        <motion.div
          layout
          className={cn(
            'bg-neutral-50 dark:bg-neutral-950',
            'relative isolate flex w-full flex-col pt-9',
          )}
          // className="relative isolate flex w-full flex-col pt-9 "
        >
          <main className="w-full flex-auto ">{children}</main>
        </motion.div>
      </motion.div>
      {/* <div
        style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
        className=" flex flex-auto  bg-white pt-14"
      >
        <main className="w-full flex-auto">{children}</main>
      </div> */}
      {/* </MotionConfig> */}
    </>
  )
}

export function RootLayout({ children }: { children: React.ReactNode }) {
  const [isUserPro, setIsUserPro] = useState(false)
  let pathname = usePathname()
  let [logoHovered, setLogoHovered] = useState(false)

  useEffect(() => {
    const fetchProStatus = async () => {
      try {
        const result = await getUserProStatus()
        if (typeof result === 'boolean') {
          setIsUserPro(result)
        } else if (typeof result === 'object' && 'success' in result) {
          // Handle the case where an object is returned
          setIsUserPro(false) // or handle it based on your logic
          console.log(result.message) // Log the error message
        } else {
          // Handle null case
          setIsUserPro(false)
        }
      } catch (error) {
        console.error('Error fetching user pro status:', error)
        setIsUserPro(false)
      }
    }

    fetchProStatus()
  }, [])

  return (
    <RootLayoutContext.Provider value={{ logoHovered, setLogoHovered }}>
      <RootLayoutInner
        isUserPro={isUserPro}
        path={pathname || ''}
        key={pathname}
      >
        {children}
      </RootLayoutInner>
    </RootLayoutContext.Provider>
  )
}
